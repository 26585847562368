import { Environment } from './environment';

export interface ApplicationLanguage {
    /**
     * The language's ISO code, eg. `de_DE`.
     */
    isoCode: string;

    /**
     * Human-readable name of the lanuage, eg. "Deutsch - DE".
     */
    name: string;

    /**
     * Short human-readable name of the lanuage, eg. "DE". Two characters maximum.
     */
    shortName: string;

    /**
     * Sets this language as default for multinational variants, eg. `de` marks
     * a language for all variants like `de_DE`, `de_AT`, etc.
     */
    defaultForLanguage: string;
}

const deDE: ApplicationLanguage = {
    isoCode: 'de_DE',
    name: 'Deutsch - DE',
    shortName: 'DE',
    defaultForLanguage: 'de',
};
const enGB: ApplicationLanguage = {
    isoCode: 'en_GB',
    name: 'English - EN',
    shortName: 'EN',
    defaultForLanguage: 'en',
};
const itIT: ApplicationLanguage = {
    isoCode: 'it_IT',
    name: 'Italiano - IT',
    shortName: 'IT',
    defaultForLanguage: 'it',
};

/**
 * Map of available languages for a given Environment. The first language becomes the
 * fallback for it's Environment.
 */
export const LanguagesForEnvironment: Map<Environment, Array<ApplicationLanguage>> = new Map([
    [Environment.DE_DEV, [deDE, enGB, itIT]],
    [Environment.DE_PROD, [deDE]],
    [Environment.DE_STAGE, [deDE, enGB, itIT]],
    [Environment.DE_UAT, [deDE, enGB, itIT]],
]);
